<template>
  <div class="era-content" @scroll="scrolls">
      <router-view />
  </div>
</template>

<script>
export default {
  name: "PageContent",
  methods: {
    scrolls(){
      console.log('sss');
    }
  }
};
</script>

<style lang="scss" scoped>
.era-content {
  /* background-color: #f0f1f4; */
  background-color: #f7f7f7;
  padding: 3.9vw 0 0;
}
</style>
